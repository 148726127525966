import { ThemeOptions } from 'vuetify/types/services/theme.d';

import colors from 'vuetify/lib/util/colors'

// These colors are derived from:
// https://mycolor.space/?hex=%23E30513&sub=1
// where the color #e30513 comes from the red in the Hazcheck logo.

const exisLogoRed = '#E30513';
const bluishCyan = '#0099C2';
const pastalGreen = '#009771';
const vividGreen = '#008F0A';
const warmOrangeBrown = '#B15F00';
const grey = '#808080';

const theme: ThemeOptions = {
  themes: {
    light: {
      primary: bluishCyan,
      secondary: pastalGreen,
      accent: exisLogoRed,
      error: '#E00020',
      warning: warmOrangeBrown,
      info: colors.cyan.base,
      success: vividGreen,
      anchor: pastalGreen,

      // Extra theme colors
      action: colors.green.base,
      disabled: grey,
    },
  },
};

export default theme;
