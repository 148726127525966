
import AxiosService from "./axios-service";
import config from "@/config";

export interface AllStatusesConfig {
  statuses: SingleStatusConfig[];
}

export interface SingleStatusConfig {
  id: number;
  mandatoryNotes: boolean;
  metadata: StatusMetadata[];
}

export interface StatusMetadata {
  type: string;
  name: string;
  isRequired: boolean | null;
  values: MetadataValue[];
  requiredConditions: OrPredicateMetadataCondition[];
}

export interface OrPredicateMetadataCondition {
  andPredicates: Condition[];
}

export interface Condition {
  path: string;
  value: string;
  predicate: string;
}

export interface MetadataValue {
  displayName: string;
  value: string;
}

export interface StatusChangeMetadataConfigModel {
  AllowCaseDetails: AllowCaseDetail[];
}

export interface AllowCaseDetail {
  source: string;
  key: string;
}

export default class ConfigurationService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async getStatusMetadataConfig(): Promise<AllStatusesConfig> {
    return this.getConfig("status-metadata");
  }

  async getStatusChangeMetadataConfig(): Promise<StatusChangeMetadataConfigModel> {
    return this.getConfig("status-change-case-detail-metadata");
  }

  private async getConfig(configType: string) {
    const uri = `${config.caseManagementApi}/configuration/${configType}`;
    const response = await this.axios.get(uri);
    return JSON.parse(response.json);
  }

  async saveStatusMetadataConfig(metadataConfig: AllStatusesConfig) {
    await this.saveConfig(metadataConfig, "status-metadata");
  }

  async saveStatusChangeMetadata(configOptions: any) {
    await this.saveConfig(configOptions, "status-change-case-detail-metadata");
  }

  private async saveConfig(configValues: any, configType: string) {
    const uri = `${config.caseManagementApi}/configuration/${configType}`;
    const body = { json: JSON.stringify(configValues) };

    await Promise.all([
      this.axios.put(uri, body, { headers: { "content-type": "application/json" } })
    ]);
  }

  async getUserDefaultFilterValue(userId: string){
    try {
      return config.staffDefaults[userId];
    }
    catch{
      return undefined;
    }
  }
}
