import {
  ExtendedMatch,
  ExtendedMatchFeedbackRequest,
  ExtendedMatchFeedbackView
} from "@/models/case-maintenance";
import AxiosService from "./axios-service";
import config from "@/config";
import { HalPagedResponse, PaginationOptions } from "@/models/hal";
import { CancelToken } from "axios";
import { Filter, FilterField } from "@/models/filters";
import { buildFilterQuery } from "@/helpers/filterHelpers";

export default class MatchService {
  axios: AxiosService;
  private defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize
  };

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async listMatches(
    options = this.defaultPagenationOptions,
    filter?: Filter[],
    filterFields?: FilterField[],
    cancelToken?: CancelToken
  ): Promise<HalPagedResponse<ExtendedMatchFeedbackView, "matches">> {
    const { defaultPageSize, caseManagementApi } = config;
    const body = {
      page: options.page || 1,
      size: options.size || defaultPageSize,

      filter: buildFilterQuery(filter, filterFields)
    };

    const matchesList = await this.axios.post<HalPagedResponse<ExtendedMatch, "matches">>(
      `${caseManagementApi}/matches`,
      body,
      {
        cancelToken
      }
    );

    return MatchService.mapMatchesHalList(matchesList, m => new ExtendedMatchFeedbackView(m));
  }

  private static mapMatchesHalList(
    response: HalPagedResponse<ExtendedMatch, "matches">,
    map: (input: ExtendedMatch) => ExtendedMatchFeedbackView
  ): HalPagedResponse<ExtendedMatchFeedbackView, "matches"> {
    return {
      ...response,
      _embedded: {
        matches: response._embedded.matches.map(x => ({
          _links: x._links,
          ...map(x)
        }))
      }
    };
  }

  async SendFeedback(x: ExtendedMatchFeedbackRequest[]): Promise<unknown> {
    const uri = `${config.caseManagementApi}/matches/feedback`;
    const result = await this.axios.post(uri, x);

    return result;
  }
}
