import config from "@/config";
import AxiosService from "./axios-service";

export default class Notary {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  public addNoteToCase(caseId: string, text: string): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${caseId}/notes`;
    const body = { text };

    return this.axios.post(uri, body);
  }
}
