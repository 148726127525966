import { Services } from '@/services';
import Vue from 'vue';
import Vuex from 'vuex';
import { VuexPersistence } from 'vuex-persist';

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({ storage: sessionStorage });

class MyStore extends Vuex.Store<any> {
  services!: Services;

  constructor() {
    super({
      plugins: [vuexPersistence.plugin],
    });
  }
}

export default new MyStore();

export function hasState(name: string): boolean {
  const state = sessionStorage.getItem('vuex');

  if (state == null) return false;

  const json = JSON.parse(state);

  return name in json;
}
