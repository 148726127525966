import { Config } from "@/config";
import Vue from "vue";
import VueRouter, { Route, RawLocation } from "vue-router";
import AuthService from "../services/auth-service";
import { loadRoutes } from './routes';

Vue.use(VueRouter);

let previousRoute: Route | null = null;
let router: VueRouter | null = null;

// export async function buildRouter(authService: AuthService): Promise<VueRouter> {
//   router = new VueRouter({
//     mode: "hash",
//     base: process.env.BASE_URL,
//     routes: await routes
//   });

//   let configLoaded = false;

//   router.beforeEach(async (to, from, next) => {
//     if (!configLoaded) {
//       await loadConfig();
//       configLoaded = true;
//     }

//     const routeIsSecure = to.matched.some(record => record.meta.isSecure);

//     if (!routeIsSecure) {
//       next();
//       return;
//     }

//     const isLoggedIn = await authService.isLoggedIn();

//     if (isLoggedIn) {
//       next();
//       return;
//     }

//     await authService.login(to.fullPath);
//   });

//   router.afterEach((to, from) => {
//     if (from.name) {
//       previousRoute = from;
//     }
//   });

//   return router;
// }

export async function buildRouter(authService: AuthService, config: Config): Promise<VueRouter> {
  router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: await loadRoutes(config),
  });

  router.beforeEach(async (to, from, next) => {
    const routeIsSecure = to.matched.some((record) => record.meta.isSecure);

    if (!routeIsSecure) {
      next();
      return;
    }

    const isLoggedIn = await authService.isLoggedIn();

    if (isLoggedIn) {
      next();
      return;
    }

    await authService.login(to.fullPath);
  });

  router.afterEach((to, from) => {
    if (from.name) {
      previousRoute = from;
    }
  });

  return router;
}
export async function backOr(route: RawLocation) {
  if (!router) return;

  if (previousRoute) {
    router.back();
  } else {
    router.push(route);
  }
}
