import config from '@/config';
import AxiosService from './axios-service';

export default class ScreeningViewService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async readScreening(id: string): Promise<any> {
    const uri = `${config.hazcheckDetectApi}/screen/${id}`;

    const screening = await this.axiosService.get(uri);

    return screening;
  }
} 