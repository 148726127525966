import { Hit, Match } from "@/models/case-maintenance.d";

function hitComparator(hit1: Hit, hit2: Hit): number {
    const libraryComparison = hit1.library.id.localeCompare(hit2.library.id);
    if (libraryComparison !== 0) return libraryComparison;

    const ruleComparison = hit1.rule.condition.localeCompare(hit2.rule.condition);
    if (ruleComparison !== 0) return ruleComparison;

    return JSON.stringify(hit1.metadata).localeCompare(JSON.stringify(hit2.metadata));
}

function compareValues<T>(a: T, b: T): number {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
  
function matchComparator(match1: Match, match2: Match): number {
    const comparisons = [
        compareValues(match1.keyword, match2.keyword),
        compareValues(match1.field, match2.field),
        compareValues(match1.text, match2.text),
        compareValues(match1.position, match2.position),
        compareValues(match1.length, match2.length),
        compareValues(match1.singleHighlight, match2.singleHighlight),
    ];

    for (const comparison of comparisons) {
        if (comparison !== 0) {
        return comparison;
        }
    }

    return 0;
}


export function hitListsAreEqual(hitList1: Hit[], hitList2: Hit[]): boolean {

    if (hitList1.length !== hitList2.length) {
        return false;
    }
    
    const sortedHitList1 = hitList1.map(hit => {
    return {...hit, matches: hit.matches.sort(matchComparator)};
    }).sort(hitComparator);

    const sortedHitList2 = hitList2.map(hit => {
    return {...hit, matches: hit.matches.sort(matchComparator)};
    }).sort(hitComparator);

    return JSON.stringify(sortedHitList1) === JSON.stringify(sortedHitList2);
}
  