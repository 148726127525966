import { SnackbarOptions } from '@/models/form';
import store, { hasState } from '@/store';
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import EcSnackbarOptions from '@/components/common/ec-snackbar';

export declare interface ApiErrorState {
  down: boolean;
  message?: string;
  date?: Date;
}

export declare interface Dialog {
  open: boolean;
  maxWidth: number;
  persistent: boolean;
  title: string;
  text: string;
  actions: DialogAction[];
}

export declare interface DialogAction {
  name: string;
  handler: () => Promise<any>;
  color?: string;
}

export declare interface DialogModel {
  maxWidth?: number;
  persistent?: boolean;
  title: string;
  text: string;
  actions: DialogAction[];
}

const shouldLoadState = hasState('app-state');

@Module({
  dynamic: true,
  store,
  name: 'app-state',
  preserveState: shouldLoadState
})

export default class AppState extends VuexModule {
  drawer: boolean | null = null;

  apiError: ApiErrorState = { down: false };

  title = 'Hazcheck Detect';

  dialog: Dialog = {
    open: false,
    persistent: true,
    maxWidth: 290,
    title: '',
    text: '',
    actions: [],
  };

  get apiFault() {
    return Boolean(this.apiError.down || this.apiError.message);
  }

  snackbarOptions: SnackbarOptions = EcSnackbarOptions.makeDefaultOptions();

  @Mutation
  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  @Mutation
  setDrawer(payload: boolean | null) {
    this.drawer = payload;
  }

  @Mutation
  setApiError(payload: ApiErrorState) {
    this.apiError = {
      down: payload?.down,
      message: payload?.message,
      date: new Date(),
    };
  }

  @Mutation
  setSnackbarOptions(payload: SnackbarOptions) {
    this.snackbarOptions = payload;
  }

  @Action({ commit: 'setApiError' })
  resetApiError(): ApiErrorState {
    return { down: false };
  }

  @Mutation
  setTitle(payload: string) {
    const newTitle = `Hazcheck Detect${payload ? `: ${payload}` : ''}`;

    document.title = newTitle;
    this.title = newTitle;
  }

  @Mutation
  setDialog(payload: Dialog) {
    this.dialog = payload;
  }

  @Mutation
  closeDialog() {
    this.dialog.open = false;
  }

  @Action
  openDialog(dialog: DialogModel): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const newActions = dialog.actions.map((action) => ({
        name: action.name,
        color: action.color,
        handler: () => {
          this.context.commit('closeDialog');
          return action.handler().then(resolve, reject);
        },
      }));

      const newDialog: Dialog = {
        open: true,
        title: dialog.title,
        text: dialog.text,
        maxWidth: dialog.maxWidth || 290,
        persistent: dialog.persistent || true,
        actions: newActions,
      };

      this.context.commit('setDialog', newDialog);
    });

    return promise;
  }
}
