import config from "@/config";
import AxiosService from "../services/axios-service";
import { Library, Rule } from "./embedded-models";

export default class CaseLibraryService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async GetLibraryValues(): Promise<{ text: string; value: string }[]> {
    const uri = `${config.hazcheckDetectApi}/libraries/`;
    return ((await this.axios.get(uri)) as Library)._embedded.libraries.map(x => {
      return { text: x.name, value: x.name };
    });
  }

  async GetRuleValues(): Promise<{ text: string; value: string }[]> {
    const uri = `${config.hazcheckDetectApi}/rules?page=1&size=100@filter= `;

    return ((await this.axios.get(uri)) as Rule)._embedded.rules.map(x => {
      return { text: x.name, value: x.name };
    });
  }
}
