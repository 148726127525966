import config from '@/config';
import uriTemplate from 'url-template';
import { User } from '@/models/case-maintenance.d';
import { HalPagedResponse, PaginationOptions } from '@/models/hal';
import AxiosService from './axios-service';
import delay from '@/helpers/delay';
import { AxiosRequestConfig } from 'axios';
import { WillHandle } from './axios-service.d';
import { CaseView } from '@/models/case-maintenance';

export type ListUserOptions = PaginationOptions & {
  includeHidden?: boolean;
};

export default class UserService {
  private defaultPagenationOptions: ListUserOptions = {
    page: 1,
    size: config.defaultPageSize,
    sort: ['name,asc'],
    includeHidden: true
  };

  axios: AxiosService;

  private users: HalPagedResponse<User, 'users'> | null = null;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  public async getName(userId: string): Promise<string> {
    let users = this.users;
    if (!users) {
      users = await this.listUsers({ size: 100 });
    }

    const user = users._embedded.users.find(x => x.id === userId);

    if (user) return user.name;

    return 'Unknown User';
  }

  public async assignCaseToUser(
    _case: CaseView,
    assignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${_case['case-id']}/assignment`;
    const body = {
      'old-assigned-to': _case['assigned-to'],
      'assigned-to': assignedTo
    };

    await Promise.all([this.axios.post(uri, body, requestConfig), delay(1000)]);
  }

  public async assignCasesToUser(
    _caseIds: string[],
    assignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/assignment`;
    const body = {
      'case-ids': _caseIds,
      'assign-to': assignedTo
    };

    await Promise.all([this.axios.post(uri, body, requestConfig), delay(1000)]);
  }

  public async unassignCase(
    _case: CaseView,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${_case['case-id']}/assignment`;
    const body = {
      'old-assigned-to': _case['assigned-to']
    };

    await Promise.all([this.axios.delete(uri, body, requestConfig), delay(1000)]);
  }

  public async assignCaseIdToUser(
    _caseId: string,
    oldAssignedTo: string | null,
    assignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${_caseId}/assignment`;
    const body = {
      'old-assigned-to': oldAssignedTo,
      'assigned-to': assignedTo
    };

    await this.axios.post(uri, body, requestConfig);
  }

  public async unassignCaseId(
    _caseId: string,
    oldAssignedTo: string | null,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${_caseId}/assignment`;
    const body = {
      'old-assigned-to': oldAssignedTo
    };

    await this.axios.delete(uri, body, requestConfig);
  }

  public async listUsers(
    options = this.defaultPagenationOptions
  ): Promise<HalPagedResponse<User, 'users'>> {
    if (this.users) return this.users;

    const { caseManagementApi } = config;
    const template = uriTemplate.parse(
      `${caseManagementApi}/users/{?page,size,sort*,includeHidden}`
    );

    const uri = template.expand({
      ...this.defaultPagenationOptions,
      ...options
    });

    const result = await this.axios.get(uri);

    this.users = result as HalPagedResponse<User, 'users'>;

    return this.users;
  }
}
