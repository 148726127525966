import config from "@/config";
import { CaseStatus } from "@/models/case-status";
import { CaseView } from "@/models/case-maintenance";
import AxiosService from "./axios-service";
import { AxiosRequestConfig } from "axios";
import { WillHandle } from "./axios-service.d";


export interface MetadataValueModel{
  key: string;
  value: string;
  displayValue: string;
}

export interface MetadataValuesModel{
  metadata: Array<MetadataValueModel>;
  note: string;
}

export default class StatusService {
  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async setCaseStatus(
    _case: CaseView,
    newStatus: CaseStatus,
    metadata: MetadataValuesModel,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<void> {
    const uri = `${config.caseManagementApi}/cases/${_case["case-id"]}/status`;
    const body = {
      'old-status': _case.status,
      'status': newStatus,
      'note': metadata.note,
      'metaData': metadata.metadata
    };

    await this.axios.put(uri, body, requestConfig)    
  }
}
