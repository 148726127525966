import Vue from "vue";
import config from "@/config"

export enum CaseStatus {
  New = 0,
  InProgress = 1,
  Closed = 2,
  Processed = 3
}

export function GetCaseStatusValues(): { text: string; value: string }[] {
  return config.caseStatus.map(
    status => ({ text: status.name, value: `${status.value}` })
  )
}

export function getStatusText(value: number|CaseStatus) {
  for(const configStatus of config.caseStatus){
    if(configStatus.value === value){
      return configStatus.name;
    }
  }
  return "Unknown";
}

Vue.filter(
  'status',
  (value: number) => getStatusText(value)
);
