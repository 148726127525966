import config, { Region } from "@/config";
import { Filter, FilterField } from "@/models/filters";

function getSpecialFilter(region?: Region): string {
  let filterCollection = "";
  if (region != undefined) {
    const field = region.field;
    const filters = region.filters;
    filters.forEach(filter => {
      filter.values.forEach(value => {
        filterCollection += `${field}=${filter.operator}${value},`;
      });
    });
    filterCollection = filterCollection.substring(0, filterCollection.length - 1);
  }
  return filterCollection;
}

function GetRegionParts(regionValue: string): string {
  const region = config.groups.regions.find(x => x.id === regionValue);
  return getSpecialFilter(region);
}

export function buildFilterQuery(
  filter?: Filter[],
  filterFields?: FilterField[]
): string | undefined {
  const filterConditions = filter?.reduce((q, f) => {
    if (f.key && f.value) {
      if (f.key != "regions") {
        const matchingKeys = filterFields?.find(x => x.key === f.key);
        const matchingPredicate = matchingKeys?.operator ? matchingKeys.operator + "|" : "";
        q.push(`${f.key}=${matchingPredicate}${f.value.replace(",", "%44")}`);
      } else {
        q.push(GetRegionParts(f.value));
      }
    }
    return q;
  }, [] as string[]);

  return filterConditions?.length ? filterConditions.join(",") : undefined;
}
