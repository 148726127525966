import { SnackbarOptions } from "@/models/form";

export default class EcSnackbarOptions {
    static makeDefaultOptions(): SnackbarOptions {
        const result = {
          message: '',
          color: '',
          value: false,
          timeout: 5000,
          closable: true,
          showProgress: false,
        } as SnackbarOptions;
    
        return result;
    }
}