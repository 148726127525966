import AxiosService from "./axios-service";
import config from '@/config';

export default class EmailService {
  constructor(private axiosService: AxiosService) { }

  resendCaseEmail(
    caseId: string,
    recipient: string,
    subject: string,
    message: string
  ): Promise<any> {
    const uri = `${config.caseManagementApi}/cases/${caseId}/resendemail`;
    const model = {
      recipient,
      subject,
      message
    };

    return this.axiosService.post(uri, model);
  }
}
