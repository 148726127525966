import { FilterField } from "@/models/filters";
import { GetCaseStatusValues } from "@/models/case-status";
import { GetRegionValues } from "@/models/case-regions";
import CaseLibraryService from "@/models/detect-getters";
import { Config } from "@/config";
import AxiosService from "@/services/axios-service";

export default class FilterService {
  constructor(private config: Config, private axiosService: AxiosService) {}

  public async getCaseFilterDetails(): Promise<FilterField[]> {
    const caseLibraryService = new CaseLibraryService(this.axiosService);
    let libraries: { text: string; value: string }[];
    if (this.config.filters.findIndex(x => x.key === "library") >= 0) {
      try {
        libraries = await caseLibraryService.GetLibraryValues();
      } catch {
        libraries = [];
      }
    }
    const filters = this.config.filters.map(filter => {
      if (filter.key === "status") {
        return {
          ...filter,
          type: "enum",
          values: GetCaseStatusValues()
        }
      } else if (filter.key === "library") {
        if (libraries.length) {
          return { ...filter, values: libraries };
        } else {
          return { ...filter, type: 'string' };
        }
      } else if (filter.key === "regions") {
        return {
          ...filter,
          type: "group",
          values: GetRegionValues()
        }
      } else {
        return filter;
      }
    });

    return Promise.resolve(filters);
  }

  public async getMatchesFilterDetails(): Promise<FilterField[]> {
    const caseLibraryService = new CaseLibraryService(this.axiosService);

    const libraries = await caseLibraryService.GetLibraryValues();
    //const rules = await caseLibraryService.GetRuleValues();

    const filters = [];
    filters.push({
      key: "library-name",
      text: "Library",
      type: "enum",
      values: libraries
    } as FilterField);
    filters.push({
      key: "status",
      text: "Status",
      type: "enum",
      values: ["Open", "Completed", "All"]
    } as FilterField);
    filters.push({
      key: "date",
      text: "Date",
      type: "dateRange",
      operator: "betweenInclusive"
    } as FilterField);
    filters.push({
      key: "text",
      text: "Extended Match Text",
      type: "string"
    } as FilterField);
    filters.push({ key: "rule-name", text: "Rule", type: "string" } as FilterField);

    return filters;
  }
}
