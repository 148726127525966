import {
  Dictionary,
  EmailTemplate,
  SendEmailTemplate,
  EmailAddress
} from '@/models/email-templates.d';
import AxiosService from './axios-service';
import config from '@/config';
import uriTemplate from 'url-template';
import { HalPagedResponse, HalResponse } from '@/models/hal';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { WillHandle, CustomErrorMessageHandle } from './axios-service.d';

export default class EmailTemplateService {
  async sendTestEmail(
    recipient: string,
    subject: string,
    body: string,
    message?: string
  ): Promise<any> {
    const uri = `${config.caseManagementApi}/emails/`;
    const model = { recipient, subject, body, message };

    return await this.axiosService.post(uri, model);
  }

  sendCaseEmail(
    caseId: string,
    templateId: string,
    recipient: string,
    message?: string,
    AdditionalBookingProperties?: Dictionary<Dictionary<string>>
  ): Promise<any> {
    const cleanRecipients = recipient.split(',').map(email => {
      return { address: email.trim() } as EmailAddress;
    });

    const sendEmailTemplate = {
      templateId: templateId,
      recipients: cleanRecipients,
      message: message,
      AdditionalBookingProperties: AdditionalBookingProperties
    } as SendEmailTemplate;

    return this.sendCaseEmailTemplate(caseId, sendEmailTemplate);
  }

  sendCaseEmailTemplate(caseId: string, emailTemplate: SendEmailTemplate): Promise<any> {
    const uri = `${config.caseManagementApi}/cases/${caseId}/emails`;

    return this.axiosService.post(uri, emailTemplate);
  }

  updateEmailTemplate(
    id: string,
    model: EmailTemplate,
    requestConfig?: AxiosRequestConfig & WillHandle
  ): Promise<EmailTemplate> {
    const uri = `${config.caseManagementApi}/email-templates/${id}`;
    const modelWithMailList = {
      ...model,
      ['mail-list']: model['mail-list'].map(email => {
        if (email) return { address: email };
      })
    };
    return this.axiosService.put(uri, modelWithMailList, requestConfig) as Promise<
      HalResponse<EmailTemplate>
    >;
  }

  createEmailTemplate(model: EmailTemplate): Promise<EmailTemplate> {
    const uri = `${config.caseManagementApi}/email-templates/`;

    const requestConfig = this.requestConfigForTemplateCreation();

    const modelWithMailList = {
      ...model,
      ['mail-list']: model['mail-list'].map(email => {
        if (email) return { address: email };
      })
    };
    return this.axiosService.post(uri, modelWithMailList, requestConfig) as Promise<
      HalResponse<EmailTemplate>
    >;
  }

  async readSingle(id: string): Promise<HalResponse<EmailTemplate>> {
    const uri = `${config.caseManagementApi}/email-templates/${id}`;

    return (await this.axiosService.get(uri)) as Promise<HalResponse<EmailTemplate>>;
  }

  constructor(private axiosService: AxiosService) {}

  async list(): Promise<HalPagedResponse<EmailTemplate, 'email-templates'>> {
    const uri = uriTemplate
      .parse(`${config.caseManagementApi}/email-templates{?page,size}`)
      .expand({ page: 1, size: 100 });

    const list = (await this.axiosService.get(uri)) as HalPagedResponse<
      EmailTemplate,
      'email-templates'
    >;
    list.page = {
      number: 1,
      totalElements: list._embedded['email-templates'].length,
      size: 100
    };

    return list;
  }

  async delete(id: string) {
    const uri = `${config.caseManagementApi}/email-templates/${id}`;

    try {
      await this.axiosService.delete(uri, undefined, {
        willHandle: (status: any) => {
          return status == 404;
        }
      });
    } catch {}
  }

  requestConfigForTemplateCreation = () => {
    return {
      getCustomErrorMessage: (response: AxiosResponse<any>) => {
        if (response.status === 409) {
          const errorsArray = response.data?.errors as [any];
          if (errorsArray.length > 0) return errorsArray[0]?.message;
          return 'The limit of 15 email templates has been reached. You cannot add another.';
        }
        return '';
      }
    } as AxiosRequestConfig & WillHandle & CustomErrorMessageHandle;
  };
}
