/* eslint-disable @typescript-eslint/no-use-before-define */
import { hitListsAreEqual } from "@/components/helper/HitComparison";
import {
  Case,
  CaseAssignment,
  CaseDetails,
  CaseEmail,
  CaseNotes,
  CaseStatusEntry,
  Hit,
  Library,
  Match,
  Rule,
  ScreenResult
} from "./case-maintenance.d";

import { CaseStatus, getStatusText } from "@/models/case-status";
import equal from "fast-deep-equal";

export class CaseView {
  ["case-id"]: string;
  ["created-at"]: Date;
  ["last-screening-date"]: Date;
  ["screen-results"]: ScreenResult[];
  ["case-details"]: CaseDetails[];
  ["hits"]: HitView[];
  ["case-notes"]: CaseNotes[];
  ["status-history"]: CaseStatusEntry[];
  ["assignment-history"]: CaseAssignment[];
  ["emails"]: CaseEmail[];
  ["status"]: CaseStatus;
  ["statusText"]: string;
  ["assigned-to"]: string | null;
  ["case-icon"]: { color: string; label: string } | undefined;

  constructor(_case: Case) {
    this["case-id"] = _case["case-id"];
    this["created-at"] = _case["created-at"];
    this["last-screening-date"] = _case["last-screening-date"];
    this["case-details"] = _case["case-details"];
    this["case-notes"] = CaseView.order(_case["case-notes"], x => x["created-at"]);
    this["status-history"] = _case["case-status"];
    this["assignment-history"] = _case.assignments;
    this["emails"] = _case.emails;
    this["status"] = CaseView.getCurrentStatus(_case);
    this["statusText"] = getStatusText(this.status);
    this["assigned-to"] = CaseView.getCurrentAssignee(_case);
    this["hits"] = CaseView.getHits(_case);
    this["screen-results"] = CaseView.getScreenResultIcons(_case);
    this["case-icon"] = CaseView.getCaseIcon(_case);
  }

  static getCaseIcon(item: Case): { color: string; label: string } | undefined {
    if (!this.isRescreening(item["screen-results"])) {
      return { color: "blue", label: "new screening" };
    }

    if (this.isReOpened(item["case-status"])) {
      return { color: "green", label: "re-open" };
    }

    if (this.isRescreening(item["screen-results"])) {
      if (this.latestScreeningHitsHaveChanged(item["screen-results"])) {
        return { color: "orange", label: "re-screening hits have changed" };
      }

      return { color: "yellow", label: "re-screening" };
    }
  }

  private static isRescreening(screenResults: ScreenResult[]): boolean {
    return screenResults.length > 1;
  }

  private static latestScreeningHitsHaveChanged(screenResults: ScreenResult[]) {
    if (screenResults.length == 1) return false;

    return !hitListsAreEqual(screenResults[0].hits, screenResults[1].hits);
  }

  private static isReOpened(caseStatusEntry: CaseStatusEntry[]): boolean {
    let history = [...caseStatusEntry];
    history = history.sort((x, y) => (x["created-at"] < y["created-at"] ? 0 : -1));
    if (history.length < 2) return false;
    return history[0].status == 0 && history[1].status == 2;
  }

  private static getCurrentAssignee(_case: Case): string | null {
    if (_case.assignments.length === 0) {
      return null;
    }

    return CaseView.latest(_case.assignments)["assigned-to"];
  }

  private static getCurrentStatus(_case: Case): CaseStatus {
    if (_case["case-status"].length === 0) {
      return CaseStatus.New;
    }

    return CaseView.latest(_case["case-status"]).status;
  }

  private static getHits(_case: Case): HitView[] {
    const rules = _case["screen-results"]
      .flatMap(x => x.hits.map(hit => ({ hit, date: x["screening-date"] })))
      .reduce((a, b) => {
        const ruleId = b.hit.rule.id;
        if (!(ruleId in a) || a[ruleId].date < b.date) {
          a[ruleId] = b;
        }
        return a;
      }, {} as { [key: string]: { hit: Hit; date: Date } });

    const orderedScreenResults = _case["screen-results"]
      .sort((x, y) => (x["screening-date"] > y["screening-date"] ? 1 : -1))
      .reverse();

    const latestScreening = orderedScreenResults[0];

    const previousScreening = orderedScreenResults.length == 1 ? null : orderedScreenResults[1];

    const hits = Object.values(rules);
    return hits.map(
      x =>
        new HitView(
          x.hit,
          x.date === latestScreening["screening-date"],
          this.getState(x, latestScreening["screening-date"], previousScreening)
        )
    );
  }

  private static getScreenResultIcons(_case: Case): ScreenResult[] {
    _case["screen-results"].map(item => {
      const modelAtTheTime = {
        ..._case,
        "screen-results": [
          ..._case["screen-results"].filter(x => x["screening-date"] <= item["screening-date"])
        ],
        "case-status": [
          ..._case["case-status"].filter(x => x["created-at"] <= item["screening-date"])
        ]
      };
      item.icon = CaseView.getCaseIcon(modelAtTheTime);
    });

    return CaseView.order(_case["screen-results"], x => x["screening-date"]);
  }

  private static getState(
    _hit: { hit: Hit; date: Date },
    latestScreeningDate: Date,
    previousScreenResult: ScreenResult | null
  ): EditState {
    if (previousScreenResult === null || _hit.date !== latestScreeningDate) return EditState.none;

    const previousHit = previousScreenResult.hits.find(x => x.rule.id == _hit.hit.rule.id);

    if (previousHit === undefined) return EditState.new;

    return equal(previousHit.matches, _hit.hit.matches) ? EditState.none : EditState.edited;
  }

  private static latest<T extends { "created-at": Date }>(items: T[]): T {
    const tempArray = [...items];
    tempArray.sort((a, b) => (b["created-at"] > a["created-at"] ? 1 : -1));
    return tempArray[0];
  }

  private static order<T>(items: T[], date: (item: T) => Date): T[] {
    const tempArray = [...items];
    return tempArray.sort((a, b) => (date(a) < date(b) ? 1 : -1));
  }
}

export enum EditState {
  none,
  new,
  edited
}

export class HitView {
  library: Library;
  rule: Rule;
  metadata: any;
  matches: MatchView[];
  isCurrent: boolean;
  editState: EditState;

  constructor(hit: Hit, isCurrent: boolean, editState: EditState) {
    this.library = hit.library;
    this.rule = hit.rule;
    this.metadata = hit.metadata;
    this.matches = hit.matches.map(x => new MatchView(x));
    this.isCurrent = isCurrent;
    this.editState = editState;

    this.matches.sort((a, b) => (a.field < b.field ? -1 : 1));
  }
}

export interface MatchLocation {
  key: string;
  indexDescription: string;
}

export class MatchView {
  field: string;
  keyword: string;
  text: string;
  position: number;
  length: number;
  location: MatchLocation[];
  singleHighlight: boolean;

  constructor(match: Match) {
    this.field = match.field;
    this.keyword = match.keyword;
    this.text = match.text;
    this.position = match.position;
    this.length = match.length;
    this.singleHighlight = match.singleHighlight;
    this.location = MatchView.parseMatchLocation(this.field);
  }

  static locationRegex = /\.([^\[\.]+)(?:\[([^\]]+)\])?/g;
  static indexRegex = /\$id == '(.*)'/;

  static parseMatchLocation(field: string): MatchLocation[] {
    const matches = [...field.matchAll(MatchView.locationRegex)];
    return matches.map(match => MatchView.parseMatchIndex(match[1], match[2]));
  }

  static parseMatchIndex(key: string, index: string): MatchLocation {
    if (!index) return { key: key, indexDescription: key };

    const match = index.match(this.indexRegex);

    if (!match) return { key: key, indexDescription: (parseInt(index) + 1).toString() };

    return { key: key, indexDescription: match[1] };
  }
}

export interface ExtendedMatch {
  id: string;
  ["extended-text"]: string;
  ["rule-name"]: string;
  ["library-name"]: string;
  text: string;
  count: number;
}

export class ExtendedMatchFeedbackView {
  id: string;
  extendedText: string;
  text: string;
  quality: MatchQuality | null;
  comments: string;
  count: number;
  ruleName: string;
  libraryName: string;

  constructor(_EMV: ExtendedMatch) {
    this.id = _EMV.id;
    this.text = _EMV.text;
    this.extendedText = _EMV["extended-text"];
    this.quality = null;
    this.comments = "";
    this.count = _EMV.count;
    this.ruleName = _EMV["rule-name"];
    this.libraryName = _EMV["library-name"];
  }
}

export enum MatchQuality {
  Bad = -1,
  Neutral = 0,
  Good = 1
}

export interface ExtendedMatchFeedbackRequest {
  extendedMatchId: string;
  comments: string;
  score: number;
}
