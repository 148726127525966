














































import '@/styles/index.scss';

import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import LayoutToolbar from '@/components/layout/Toolbar.vue';
import LayoutDrawer from '@/components/layout/Drawer.vue';
import LayoutMain from '@/components/layout/Main.vue';
import LayoutFooter from '@/components/layout/Footer.vue';
import { RouteConfig } from 'vue-router';

import { getModule } from 'vuex-module-decorators';
import AppState, { ApiErrorState } from '@/store/modules/app-module';
import EcSnackBar from '@/components/common/ec-snackbar.vue';
import { SnackbarOptions } from './models/form';

const appState = getModule(AppState);

@Component({
  components: {
    LayoutToolbar,
    LayoutDrawer,
    LayoutMain,
    LayoutFooter,
    EcSnackBar,
  },
})
export default class App extends Vue {
  apiErrorSnackbar = false;

  apiDownSnackbar = false;

  apiErrorMessage = '';

  snackbarLocalOptions: SnackbarOptions = {} as SnackbarOptions;

  get apiError() {
    return appState.apiError;
  }

  get dialog() {
    return appState.dialog;
  }

  get snackbarOptions() {
    return appState.snackbarOptions;
  }

  @Watch('$route')
  onRouteChange(route: RouteConfig) {
    appState.setTitle(route.meta.title);
  }

  @Watch('apiError')
  onApiErrorChange(value: ApiErrorState) {
    this.apiErrorMessage = value.message || '';
    this.apiErrorSnackbar = Boolean(value.message);
    this.apiDownSnackbar = value.down;
  }

  @Watch('snackbarOptions')
  onSnackbarOptionsChange(value: SnackbarOptions) {
    this.snackbarLocalOptions = value;
  }
}
