import './class-component-hooks'

import Vue from 'vue';
import plugins from '@/plugins/index';
import { servicesLoading } from '@/services/index';
import '@/filters/index';

import App from './App.vue';

import './registerServiceWorker';

import { buildRouter } from './router';
import store from './store';
import { loadConfig } from './config';

Vue.config.productionTip = false;

(async function(){
  const config = await loadConfig();
  const services = await servicesLoading;
  await services.AuthService.init();
  const router = await buildRouter(services.AuthService, config);

  const vue = new Vue({
    router,
    store,
    ...plugins,
    provide: services,
    render: (h) => h(App),
  });

  store.services = services;

  (window as any).$$vue = vue;

  vue.$mount('#app');
})();

