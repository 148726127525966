










import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import VersionService from '../../services/version-service';

@Component
export default class Footer extends Vue {
  @Inject() VersionService!: VersionService;

  versionString = "";

  async mounted() {
    this.versionString = await this.VersionService.apiVersion();
  }
}

