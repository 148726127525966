import { Region } from "./../config";
import { Case } from "@/models/case-maintenance.d";
import config from "@/config";
import { HalLink, HalPagedResponse, HalResponse, PaginationOptions } from "@/models/hal.d";
import { CancelToken } from "axios";
import AxiosService from "./axios-service";
import { CaseView } from "@/models/case-maintenance";
import { Filter, FilterField } from "@/models/filters";
import delay from "@/helpers/delay";
import { distinctBy } from "@/helpers/distinct";

export default class CaseService {
  private defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize
  };

  axios: AxiosService;

  constructor(axios: AxiosService) {
    this.axios = axios;
  }

  async readSingle(caseId: string, cancelToken?: CancelToken): Promise<HalResponse<CaseView>> {
    const uri = `${config.caseManagementApi}/cases/${caseId}`;

    const _case = this.axios.get(uri, { cancelToken }) as Promise<HalResponse<Case>>;

    return CaseService.mapCaseHal(await _case, c => new CaseView(c));
  }

  async listCases(
    options = this.defaultPagenationOptions,
    filter?: Filter[],
    assignment?: string | null,
    filterFields?: FilterField[],
    cancelToken?: CancelToken
  ): Promise<HalPagedResponse<CaseView, 'cases'>> {
    const { caseManagementApi } = config;
    const body = this.mapCaseListBody(options, filter, assignment, filterFields)
    const list = this.axios.post(`${caseManagementApi}/cases`, body, { cancelToken });

    const caseList = (await list) as HalPagedResponse<Case, 'cases'>

    return CaseService.mapCaseHalList(caseList, (c) => new CaseView(c));
  }

  mapCaseListBody(
    options = this.defaultPagenationOptions,
    filter?: Filter[],
    assignment?: string | null,
    filterFields?: FilterField[]
  ){
      return{
      ...{ page: 1, size: options.size, sort: {"created-at": "asc"}},
      ...{ page: options.page, size: options.size, sort: this.getUserSortParameters(options.sort ?? [])},
      filter: this.buildFilterQuery(filter, filterFields),
      assignment: assignment
    };
  }

  getUserSortParameters(optionsSort: string[]): {[id: string]: string} {
    const userSortParameters: {[id: string]: string} = {};
    for (let index = 0; index < (optionsSort.length); index++) {

      const parts = optionsSort[index].split(',');
      if (parts.length == 2){
        const secondKey = parts[0];
        userSortParameters[secondKey] = parts[1];
        break;
      }
    }
    return userSortParameters;
  }

  async listAllCases(
    filter?: Filter[],
    filterFields?: FilterField[],
    assignment?: string | null,
    progressCallback?: (count: number, total: number) => void,
    cancelToken?: CancelToken, 
    maxCases?: number
  ): Promise<HalResponse<CaseView>[]> {
    
    progressCallback = progressCallback || (() => { /*noop*/ });
    const { caseManagementApi } = config;
    const body = this.mapCaseListBody(
      {...this.defaultPagenationOptions, size: 100}, 
      filter, 
      assignment, 
      filterFields
    )

    let response = await this.axios.post(`${caseManagementApi}/cases`, body, { cancelToken });
    let allCases = response._embedded.cases;

    await delay(100);

    progressCallback(0, 1);
    const limit = maxCases ?? response.page.totalElements
    
    while (response._links && response._links.next && allCases.length <= limit) {
      const totalPages = Math.ceil(response.page.totalElements / response.page.size);
      progressCallback(response.page.number, totalPages);

      await delay(100);
      const nextHref = (response._links.next as HalLink).href;
      response = await this.axios.post(nextHref, {...body, page: response.page.number + 1}, { cancelToken }) as HalPagedResponse<Case, 'cases'>;
      allCases = allCases.concat(response._embedded.cases);
    }

    progressCallback(response.page.number, response.page.number);
    await delay(100);

    return allCases
      .filter(distinctBy("case-id"))
      .map((_case: HalResponse<Case>) => CaseService.mapCaseHal(_case, x => new CaseView(x)));
  }

  private static mapCaseHalList(
    response: HalPagedResponse<Case, "cases">,
    map: (input: Case) => CaseView
  ): HalPagedResponse<CaseView, "cases"> {
    return {
      ...response,
      _embedded: {
        cases: response._embedded.cases.map(x => ({
          _links: x._links,
          ...map(x)
        }))
      }
    };
  }

  private static mapCaseHal(
    response: HalResponse<Case>,
    map: (input: Case) => CaseView
  ): HalResponse<CaseView> {
    return {
      ...{ _links: response._links },
      ...map(response)
    };
  }

  private buildFilterQuery(filter?: Filter[], filterFields?: FilterField[]): string | undefined {
    const filterConditions = filter?.reduce((q, f) => {
      if (f.key && f.value) {
        if (f.key != "regions") {
          const matchingKeys = filterFields?.find(x => x.key === f.key);
          const matchingPredicate = matchingKeys?.operator ? matchingKeys.operator + "|" : "";
          q.push(`${f.key}=${matchingPredicate}${f.value.replace(",", "%44")}`);
        } else {
          q.push(this.GetRegionParts(f.value));
        }
      }
      return q;
    }, [] as string[]);

    return filterConditions?.length ? filterConditions.join(",") : undefined;
  }

  private GetRegionParts(regionValue: string): string {
    const region = config.groups.regions.find(x => x.id === regionValue);
    return this.getSpecialFilter(region);
  }

  private getSpecialFilter(region?: Region): string {
    let filterCollection = "";
    if (region != undefined) {
      const field = region.field;
      const filters = region.filters;
      filters.forEach(filter => {
        filter.values.forEach(value => {
          filterCollection += `${field}=${filter.operator}${value},`;
        });
      });
      filterCollection = filterCollection.substring(0, filterCollection.length - 1);
    }
    return filterCollection;
  }
}
